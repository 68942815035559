<template>
  <div>
    <b-form>
      <div class="container wizard">
        <div class="row">
          <div class="col-12 p-0">
            <h2>{{ title }}</h2>
          </div>
        </div>

        <validation-observer ref="observer" v-slot="{ handleSubmit }">
          <h5>Ingrese por favor el número de {{ typeIdentification }}</h5>
          <b-form @submit.stop.prevent="handleSubmit(next)" autocomplete="off">
            <b-row class="mt-4">
              <b-col md="4" cols="12">
                <TextComponent :form="form" name="identification" rules="required" />
              </b-col>
            </b-row>
            <b-row align-h="end" class="mt-4">
              <b-col md="4" cols="4" style="display: flex; justify-content: flex-end">
                <b-button type="submit" variant="primary">Siguiente</b-button>
              </b-col>
            </b-row>
          </b-form>
        </validation-observer>
        <div v-if="message">
          <b-alert show variant="info">
            <i class="info-icon"></i>
            <div style="width: 100%">
              <h4 class="alert-heading"></h4>
              <p class="mb-0">{{ message }}</p>
            </div>
          </b-alert>
        </div>
      </div>
    </b-form>
  </div>
</template>

<script>
import ProcessService from "@/services/process.service";
import moment from "moment";
import TextComponent from "../../../components/controls/TextComponent.vue";
import { TIPOS_IDENTIFICACIONES } from "../../../data/panama/Selects";

export default {
  components: { TextComponent },
  data() {
    return {
      form: {},
      message: undefined,
      title: "Iniciando el proceso",
      typeIdentification: "?",
    };
  },
  mounted() {
    this.$store.dispatch("app/loading", true);
    const id = this.$route.params.id ?? "";

    ProcessService.getData(id === "" ? "SN" : id)
      .then((response) => {
        // Se marca el id de proceso dentro del form para ser usado de el em
        const form = this.initValues({ ...response.formData, processId: id });

        this.$store.dispatch("wizard/save", { index: 0, form, id });

        this.$store.dispatch("app/loading", false);
      })
      .catch((e) => {
        const { message } = e.response.data;
        //TODO
        // this.$store.dispatch("app/token", "");
        this.$store.dispatch("app/loading", false);
        this.message = message ? message : "";
        this.title = "Proceso finalizado";
      });
  },
  methods: {
    initValues(form) {
      const { birthDay, effectiveDate, companyDateFrom, serviceDateFrom, policyDateFrom } = form;

      form.birthDayFormat = this.formateValue(birthDay);

      //Patrono
      form.companyDateFromText = this.formateValueText(companyDateFrom);
      form.serviceDateFromText = this.formateValueText(serviceDateFrom);
      form.policyDateFromText = this.formateValueText(policyDateFrom);
      form.effectiveDateText = this.formateValueText(effectiveDate);

      form.beneficiarios = form.beneficiarios.map((element) => {
        if (element.birthDay) {
          var years = moment().diff(element.birthDay, "years", false);
          return { ...element, age: years };
        } else {
          return element;
        }
      });

      form.suplentes = form.suplentes.map((element) => {
        if (element.birthDay) {
          var years = moment().diff(element.birthDay, "years", false);
          return { ...element, age: years };
        } else {
          return element;
        }
      });

      form.dependientes = form.dependientes.map((element) => {
        if (element.birthDay) {
          return {
            ...element,
            birthDay: moment(element.birthDay).format("YYYY-MM-DD"),
          };
        } else {
          return element;
        }
      });

      const typeIdentification = TIPOS_IDENTIFICACIONES.find(
        (e) => e.value === form.identificationType
      );

      this.typeIdentification = typeIdentification ? typeIdentification.text : "";

      return form;
    },
    formateValue(date) {
      if (date && date != "") {
        const y = date.substring(0, 4);
        const m = date.substring(4, 6);
        const d = date.substring(6, 8);

        return `${y}-${m}-${d}`;
      } else return "";
    },
    formateValueText(date) {
      if (date && date != "") {
        const y = date.substring(0, 4);
        const m = date.substring(4, 6);
        const d = date.substring(6, 8);

        return `${d}/${m}/${y}`;
      } else return "";
    },
    next() {
      const { identification } = this.$store.state.wizard.form;

      if (this.form.identification !== identification) {
        this.$bvToast.toast(`No coincide el numero de identificación`, {
          title: "Error de verificación",
          variant: "danger",
          solid: true,
        });
      } else {
        this.$bvToast.toast(`Ingresando a la firma del documento`, {
          title: "Correcta verificación",
          variant: "success",
          solid: true,
        });

        this.$router.push("/form/panama-1.html");
      }
    },
  },
};
</script>
