<template>
  <validation-provider :name="label" :rules="rules" v-slot="validationContext">
    <b-form-group :id="name" :label="label" :label-for="'input-' + name" label-cols="4">
      <b-form-checkbox-group
        :id="'input-' + name"
        v-model="form[name]"
        :state="getValidationState(validationContext)"
        :aria-describedby="name + '-live-feedback'"
        :options="options"
        :disabled="disabled"
      ></b-form-checkbox-group>
      <b-form-invalid-feedback :id="name + '-live-feedback'">{{
        validationContext.errors[0]
      }}</b-form-invalid-feedback>
    </b-form-group>
  </validation-provider>
</template>

<script>
export default {
  name: "CheckComponent",
  props: ["form", "name", "label", "rules", "options", "disabled"],
  data() {
    return {
      selectedItem: null,
    };
  },
  methods: {
    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null;
    },
  },
};
</script>

<style lang="scss" scoped></style>
