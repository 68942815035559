export function saltarBeneficiarios(observations) {
  return observations && observations.includes("SV04");
}

export function saltarDependientes(observations) {
  return (
    observations &&
    (observations.includes("SV01") ||
      observations.includes("SV05") ||
      observations.includes("SV06"))
  );
}

export function planType(observations) {
  //V-G-D-A
  if (observations.includes("SV01")) {
    return ["V"];
  }
  if (observations.includes("SV02")) {
    return ["V", "G"];
  }
  if (observations.includes("SV03")) {
    return ["V", "G", "D"];
  }
  if (observations.includes("SV04")) {
    return ["G", "D"];
  }
  if (observations.includes("SV05")) {
    return ["V", "A"];
  }
  if (observations.includes("SV06")) {
    return ["A"];
  }

  return [];
}

export function planDepentienteType(observations) {
  if (observations.includes("SV02")) {
    return "G";
  }
  if (observations.includes("SV03")) {
    return "GD";
  }
  if (observations.includes("SV04")) {
    return "D";
  }

  return "";
}
