<template>
  <validation-provider :name="label" v-slot="validationContext" :rules="rules">
    <b-form-group :id="name" :label="label" :label-for="'input-' + name" :label-cols="labelcols">
      <b-form-radio-group
        :id="'input-' + name"
        :label="label"
        v-model="form[name]"
        :state="getValidationState(validationContext)"
        :aria-describedby="name + '-live-feedback'"
        :options="options"
        :disabled="disabled"
      ></b-form-radio-group>

      <b-form-invalid-feedback
        :id="name + '-live-feedback'"
        :state="getValidationState(validationContext)"
        >{{ validationContext.errors[0] }}</b-form-invalid-feedback
      >
    </b-form-group>
  </validation-provider>
</template>

<script>
export default {
  name: "RadioComponent",
  props: ["form", "name", "label", "rules", "options", "labelcols", "disabled"],
  data() {
    return {
      selectedItem: null,
    };
  },
  methods: {
    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null;
    },
  },
};
</script>

<style lang="scss" scoped></style>
