<template>
  <validation-provider :name="label" v-slot="validationContext" :rules="rules">
    <b-form-group
      :id="name"
      :label="label"
      :label-for="'input-' + name"
      :label-cols="labelcols"
      :content-cols="contentcols ? contentcols : undefined"
    >
      <b-input-group prepend="CR">
        <b-form-input
          :id="'input-' + name"
          :placeholder="placeholder"
          v-model="form[name]"
          :state="getValidationState(validationContext)"
          :aria-describedby="name + '-live-feedback'"
          :type="type ? type : 'text'"
          :disabled="disabled"
          @change="onChangeInternal($event)"
        ></b-form-input>
        <b-form-invalid-feedback :id="name + '-live-feedback'">{{
          validationContext.errors[0]
        }}</b-form-invalid-feedback>
      </b-input-group>
    </b-form-group>
  </validation-provider>
</template>

<script>
export default {
  name: "TextComponent",
  props: [
    "form",
    "name",
    "label",
    "rules",
    "type",
    "placeholder",
    "disabled",
    "labelcols",
    "contentcols",
  ],
  data() {
    return {
      selectedItem: null,
    };
  },
  methods: {
    onChangeInternal($event) {
      this.$emit("onChange", $event);
    },
    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null;
    },
  },
};
</script>

<style lang="scss" scoped></style>
