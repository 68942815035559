<template>
  <div>
    <b-container>
      <div>
        <b-modal v-model="modalShow">{{ messagge }}</b-modal>
      </div>
      <validation-observer ref="observer" v-slot="{ handleSubmit }">
        <b-form @submit.stop.prevent="handleSubmit(next)" autocomplete="off">
          <b-card>
            <template #header>
              <h6 class="mb-0">Datos para ser completados por el Empleado:</h6>
            </template>
            <b-row>
              <!-- FIRST LINE -->
              <b-col md="3" cols="12">
                <TextComponent
                  :form="form"
                  name="lastName"
                  label="Primer Apellido"
                  rules="required"
                  disabled
                />
              </b-col>
              <b-col md="3" cols="12">
                <TextComponent
                  :form="form"
                  name="secondLastName"
                  label="Segundo Apellido"
                  disabled
                />
              </b-col>

              <b-col md="3" cols="12">
                <TextComponent
                  :form="form"
                  name="firstName"
                  label="Primer Nombre"
                  rules="required"
                  disabled
                />
              </b-col>

              <b-col md="3" cols="12">
                <TextComponent :form="form" name="secondFirstName" label="Segundo Nombre" />
              </b-col>

              <!-- SECOND LINE -->

              <b-col md="3" cols="12">
                <TextComponent
                  :form="form"
                  name="birthDayFormat"
                  label="Fecha de Nacimiento"
                  rules="required"
                  type="date"
                  disabled
                />
              </b-col>

              <b-col md="6" cols="12">
                <TextComponent
                  :form="form"
                  name="nationality"
                  label="Nacionalidad"
                  rules="required"
                />
              </b-col>

              <b-col md="3" cols="12">
                <RadioComponent
                  :form="form"
                  name="sex"
                  label="Sexo"
                  rules="required"
                  disabled
                  :options="[
                    {
                      value: 'M',
                      text: 'Masculino',
                    },
                    {
                      value: 'F',
                      text: 'Femenino',
                    },
                  ]"
                />
              </b-col>

              <!-- THIRD LINE -->
              <!-- C = CI; O= CR,ENER,DIMEX,DIDI -->
              <b-col md="6" cols="12">
                <SelectComponent
                  :form="form"
                  name="identificationType"
                  label="Tipo de Identificación"
                  rules="required"
                  :options="control.IdentificationsType"
                  disabled
                />
              </b-col>

              <b-col md="6" cols="12">
                <TextComponent
                  :form="form"
                  name="identification"
                  label="Número de Identificación"
                  rules="required"
                  disabled
                />
              </b-col>

              <!-- FOURTH LINE -->

              <b-col md="12" cols="12">
                <TextComponent :form="form" name="adress" label="Dirección" rules="required" />
              </b-col>

              <!-- FIFTH LINE -->
              <b-col md="4" cols="12">
                <TextComponent :form="form" name="apart" label="Apartado" />
              </b-col>

              <b-col md="4" cols="12">
                <TextComponent :form="form" name="phone" label="Teléfono" rules="required" />
              </b-col>

              <b-col md="4" cols="12">
                <TextComponent :form="form" name="phoneOffice" label="Teléfono Oficina" />
              </b-col>

              <!-- 6 LINE -->
              <b-col md="4" cols="12">
                <RadioComponent
                  :form="form"
                  name="money"
                  label="Moneda"
                  rules="required"
                  :disabled="form.moneyDisabled"
                  :options="[
                    { value: 'D', text: 'Dólares' },
                    { value: 'C', text: 'Colones' },
                  ]"
                />
              </b-col>

              <b-col md="4" cols="12">
                <IBANComponent
                  :form="form"
                  type="number"
                  name="iban"
                  label="IBAN"
                  rules="required|min:20|max:20|Digit"
                  placeholder="Solo se deben ingresen dígitos"
                />
              </b-col>

              <b-col md="4" cols="12">
                <TextComponent :form="form" name="bank" label="Bank" rules="required" />
              </b-col>

              <!-- 7 LINE -->

              <b-col md="12" cols="12">
                <TextComponent
                  :form="form"
                  name="email"
                  label="Correo Electrónico"
                  rules="required"
                  type="email"
                  disabled
                />
              </b-col>
            </b-row>

            <!-- ENVIO -->
            <b-row align-h="end" class="mt-4">
              <b-col md="4" cols="4" style="display: flex; justify-content: flex-end">
                <b-button type="submit" variant="primary">Siguiente</b-button>
              </b-col>
            </b-row>
          </b-card>
        </b-form>
      </validation-observer>
    </b-container>
  </div>
</template>

<script>
import RadioComponent from "@/components/controls/RadioComponent.vue";
import SelectComponent from "@/components/controls/SelectComponent.vue";
import TextComponent from "@/components/controls/TextComponent.vue";
import IBANComponent from "../../../components/controls/IBANComponent.vue";
import { TIPOS_IDENTIFICACIONES } from "../../../data/costarica/Selects";
import { saltarBeneficiarios, saltarDependientes } from "../../../helpers/reglasCostaRica";

export default {
  name: "Home",
  components: {
    TextComponent,
    RadioComponent,
    SelectComponent,
    IBANComponent,
  },
  data: function () {
    return {
      messagge: "",
      control: { LanguageDisabled: 1, IdentificationsType: TIPOS_IDENTIFICACIONES },
      form: {},
      modalShow: false,
      sessionId: "",
      shouldDisable: true,
    };
  },
  mounted: function () {
    this.$store.dispatch("app/loading", true);

    const { form } = this.$store.state.wizard;

    if (form === undefined || form === null || form === "") {
      this.$router.push("/form/costarica/");
      return;
    }

    this.form = form;

    // Ajustes para moneda
    this.form.moneyDisabled = this.form.money === "C";

    this.$store.dispatch("app/loading", false);
  },
  methods: {
    next() {
      const form = { ...this.form };

      const saltar_beneficiarios = saltarBeneficiarios(this.form.observations);

      const saltar_dependientes = saltarDependientes(this.form.observations);

      let nextForm = 2;

      if (saltar_beneficiarios) {
        nextForm = 3;
        if (saltar_dependientes) {
          nextForm = 4;
        }
      }

      this.$store
        .dispatch("wizard/save", { index: nextForm - 1, form })
        .then(this.$router.push(`/form/costarica-${nextForm}.html`));
    },
  },
};
</script>

<style scoped>
img.preview {
  width: 500px;
  background-color: white;
  border: 1px solid #ddd;
  padding: 5px;
}
.photo-preview {
  width: 150px;
  background-color: white;
  border: 1px solid #ddd;
  padding: 5px;
}
.fz16 {
  font-size: 16px;
}
.w100 {
  width: 100%;
}
.fr {
  float: right;
}
.furnituresContainer {
  border-bottom: 1px solid #ced4da;
}
.container {
  max-width: 1600px;
}
#input-BirthDay {
  margin-left: 3px;
}
.col-5 {
  background-color: red;
}
</style>
