import { render, staticRenderFns } from "./DateComponent.vue?vue&type=template&id=70840e74&scoped=true"
import script from "./DateComponent.vue?vue&type=script&lang=js"
export * from "./DateComponent.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "70840e74",
  null
  
)

export default component.exports