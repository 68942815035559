<!-- eslint-disable vue/valid-v-model -->
<template>
  <div>
    <b-container>
      <div>
        <b-modal v-model="modalShow">{{ messagge }}</b-modal>
      </div>
      <validation-observer ref="observer" v-slot="{ handleSubmit }">
        <b-form @submit.stop.prevent="handleSubmit(next)" autocomplete="off">
          <b-card>
            <template #header>
              <h6 class="mb-0">DEPENDIENTES (si aplican)</h6>
            </template>
            <div class="table-responsive">
              <table class="table-one">
                <tr>
                  <!-- Headers-->
                  <th>Nombre</th>
                  <th>Apellido</th>
                  <th>Plan</th>
                  <th>Parentesco</th>
                  <th>Sexo</th>
                  <th>Fecha Nacimiento (mm/dd/aaaa)</th>
                  <th>Nro de Identificación</th>
                  <th>Email</th>
                </tr>
                <tr v-for="(item, index) in form.dependientes" v-bind:key="index">
                  <!-- Rows-->
                  <td style="width: 20%">
                    <b-form-input
                      type="text"
                      v-model="item.name"
                      :state="getValidationName(index)"
                    ></b-form-input>
                  </td>

                  <td style="width: 15%">
                    <b-form-input
                      type="text"
                      v-model="item.lastName"
                      :state="getValidationLastName(index)"
                    ></b-form-input>
                  </td>

                  <td style="width: 10%">
                    <b-form-select
                      v-model="item.plan"
                      :options="control.TIPOS_PLAN"
                      :state="getValidationPlan(index)"
                    ></b-form-select>
                  </td>

                  <td style="width: 5%">
                    <b-form-select
                      v-model="item.relation"
                      :options="control.TIPOS_DEPENDIENTES"
                      :state="getValidationRelation(index)"
                    ></b-form-select>
                  </td>

                  <td style="width: 7%">
                    <validation-provider name="label">
                      <b-form-select
                        v-model="item.sex"
                        :options="control.TIPOS_SEXO"
                        :state="getValidationSex(index)"
                      ></b-form-select>
                    </validation-provider>
                  </td>

                  <td style="width: 7%">
                    <DateGrid
                      :form="item"
                      name="birthDay"
                      :state="getValidationBirthDay(index)"
                    ></DateGrid>
                  </td>
                  <td style="width: 5%">
                    <b-form-input
                      type="text"
                      v-model="item.document"
                      :state="getValidationDocument(index)"
                      :disabled="getDisabledEdad(index)"
                    ></b-form-input>
                  </td>
                  <td style="width: 20%">
                    <b-form-input
                      type="email"
                      v-model="item.email"
                      :state="getValidationEmail(index)"
                      :disabled="getDisabledEdad(index)"
                    ></b-form-input>
                  </td>

                  <!-- BUTTONS -->
                  <td style="width: 5%">
                    <b-btn
                      v-if="!rowEmpty(index)"
                      pill
                      variant="outline-danger"
                      @click="limpiar(index)"
                      >Borrar</b-btn
                    >
                  </td>
                </tr>
              </table>
            </div>

            <!-- ENVIO -->
            <b-row align-h="end" class="mt-4">
              <b-col md="4" cols="4" style="display: flex; justify-content: flex-end">
                <b-button @click="previous" variant="secondary">Anterior</b-button>
                <b-button type="submit" variant="primary">Siguiente</b-button>
              </b-col>
            </b-row>
          </b-card>
        </b-form>
      </validation-observer>
    </b-container>
  </div>
</template>

<script>
import DateGrid from "../../../components/controls/DateGrid.vue";
import { TIPOS_DEPENDIENTES, TIPOS_PLAN, TIPOS_SEXO } from "../../../data/guatemala/Selects";
import { getAge } from "../../../helpers/dateHelper";
import {
  conyugeRepetido,
  documentosDuplicados,
  emailIgualATitular,
  emailsDuplicados,
} from "../../../helpers/dependientesHelper";
import { saltarBeneficiarios } from "../../../helpers/reglasGuatemala";

const edadMaximaConyuge = 99;
const edadMinimaConyuge = 18;
const edadMaximaHijo = 24;

export default {
  name: "Home",
  components: { DateGrid },
  computed: {},
  data: function () {
    return {
      messagge: "",
      control: {
        LanguageDisabled: 1,
        TIPOS_DEPENDIENTES,
        TIPOS_SEXO,
        TIPOS_PLAN,
      },
      form: {},
      modalShow: false,
      sessionId: "",
      shouldDisable: true,
    };
  },
  mounted: function () {
    this.$store.dispatch("app/loading", true);
    const { form } = this.$store.state.wizard;
    if (form === undefined || form === null || form === "") {
      this.$router.push("/");
    }
    this.form = form;
    this.$store.dispatch("app/loading", false);
  },
  methods: {
    previous() {
      const form = { ...this.form };

      const saltar_beneficiarios = saltarBeneficiarios(this.form.observations);

      if (saltar_beneficiarios === true)
        this.$store
          .dispatch("wizard/save", { index: 0, form })
          .then(this.$router.push("/form/guatemala-1.html"));
      else
        this.$store
          .dispatch("wizard/save", { index: 1, form })
          .then(this.$router.push("/form/guatemala-2.html"));
    },
    next() {
      if (this.validation()) {
        this.$store
          .dispatch("wizard/save", this.form)
          .then(this.$router.push("/form/guatemala-4.html"));
      }
    },
    rowEmpty(index) {
      return (
        this.form.dependientes[index].name == "" &&
        this.form.dependientes[index].lastName == "" &&
        this.form.dependientes[index].plan == "" &&
        this.form.dependientes[index].relation == "" &&
        this.form.dependientes[index].birthDay == "" &&
        this.form.dependientes[index].sex == ""
      );
    },
    limpiar(index) {
      this.form.dependientes[index].plan = "";
      this.form.dependientes[index].lastName = "";
      this.form.dependientes[index].name = "";
      this.form.dependientes[index].relation = "";
      this.form.dependientes[index].birthDay = "";
      this.form.dependientes[index].sex = "";
      this.form.dependientes[index].document = "";
      this.form.dependientes[index].email = "";
    },
    getValidationName(index) {
      const state = this.rowEmpty(index) || this.form.dependientes[index].name != "";

      if (state && this.form.dependientes[index].name == "") {
        return null;
      } else {
        return state;
      }
    },
    getValidationLastName(index) {
      const state = this.rowEmpty(index) || this.form.dependientes[index].lastName != "";

      if (state && this.form.dependientes[index].lastName == "") {
        return null;
      } else {
        return state;
      }
    },
    getValidationRelation(index) {
      const state = this.rowEmpty(index) || this.form.dependientes[index].relation != "";

      if (state && this.form.dependientes[index].relation == "") {
        return null;
      } else {
        return state;
      }
    },
    getValidationDocument(index) {
      const state =
        this.rowEmpty(index) ||
        this.getDisabledEdad(index) ||
        this.form.dependientes[index].document != "";

      if (state && this.form.dependientes[index].document == "") {
        return null;
      } else {
        return state;
      }
    },
    getValidationEmail(index) {
      const state =
        this.rowEmpty(index) ||
        this.getDisabledEdad(index) ||
        this.form.dependientes[index].email != "";

      if (state && this.form.dependientes[index].email == "") {
        return null;
      } else {
        return state;
      }
    },

    getDisabledEdad(index) {
      if (this.rowEmpty(index) || this.form.dependientes[index].birthDay == "") {
        return true;
      }

      const birthDay = this.form.dependientes[index].birthDay;

      const edad = getAge(birthDay, "MM/DD/YYYY");

      if (edad < 18) {
        this.form.dependientes[index].email = "";
        this.form.dependientes[index].document = "";
      }
      return edad < 18;
    },

    getValidationBirthDay(index) {
      let state = this.rowEmpty(index) || this.form.dependientes[index].birthDay != "";

      const birthDay = this.form.dependientes[index].birthDay;

      if (this.form.dependientes[index].birthDay != "") {
        const edad = getAge(birthDay, "MM/DD/YYYY");

        if (this.form.dependientes[index].relation == "H") state = state && edad <= edadMaximaHijo;

        if (this.form.dependientes[index].relation == "C")
          state = state && edad >= edadMinimaConyuge && edad <= edadMaximaConyuge;
      }

      if (state && this.form.dependientes[index].birthDay == "") {
        return null;
      } else {
        return state;
      }
    },

    // eslint-disable-next-line no-unused-vars
    getValidationSex(index) {
      const state = this.rowEmpty(index) || this.form.dependientes[index].sex != "";

      if (state && this.form.dependientes[index].sex == "") {
        return null;
      } else {
        return state;
      }
    },

    getValidationPlan(index) {
      const state = this.rowEmpty(index) || this.form.dependientes[index].plan != "";
      if (state && this.form.dependientes[index].plan == "") {
        return null;
      } else {
        return state;
      }
    },

    validation() {
      let ok = true;

      if (emailIgualATitular(this)) {
        return false;
      }

      if (emailsDuplicados(this)) {
        return false;
      }

      if (documentosDuplicados(this)) {
        return false;
      }

      if (conyugeRepetido(this)) {
        return false;
      }

      for (let i = 0; i < this.form.dependientes.length; i++) {
        if (!this.rowEmpty(i)) {
          let validacionEmail = this.getValidationEmail(i);
          validacionEmail = validacionEmail == null ? true : validacionEmail;

          let validacionDocument = this.getValidationDocument(i);
          validacionDocument = validacionDocument == null ? true : validacionDocument;

          ok =
            ok &&
            this.getValidationName(i) &&
            this.getValidationLastName(i) &&
            this.getValidationRelation(i) &&
            this.getValidationBirthDay(i) &&
            this.getValidationSex(i) &&
            this.getValidationPlan(i) &&
            validacionDocument &&
            validacionEmail;
        }
      }
      return ok;
    },
  },
};
</script>

<style scoped>
img.preview {
  width: 500px;
  background-color: white;
  border: 1px solid #ddd;
  padding: 5px;
}
.photo-preview {
  width: 150px;
  background-color: white;
  border: 1px solid #ddd;
  padding: 5px;
}
.fz16 {
  font-size: 16px;
}
.w100 {
  width: 100%;
}
.fr {
  float: right;
}
.furnituresContainer {
  border-bottom: 1px solid #ced4da;
}
.container {
  max-width: 100% !important;
}
#input-BirthDay {
  margin-left: 3px;
}
.col-5 {
  background-color: red;
}

.td-button {
  width: 80px;
  overflow: hidden;
  display: inline-block;
  white-space: nowrap;
}
</style>
