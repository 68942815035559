<template>
  <validation-provider :name="label" v-slot="validationContext" :rules="rules">
    <b-form-group
      :id="name"
      :label="label"
      :label-for="'input-' + name"
      :label-cols="labelcols"
      :content-cols="contentcols ? contentcols : undefined"
    >
      <b-form-input
        :id="'input-' + name"
        :name="name"
        type="text"
        :placeholder="placeholder"
        v-model="form[name]"
        :state="getValidationState(validationContext)"
        :aria-describedby="name + '-live-feedback'"
        :disabled="disabled"
        @change="onChangeInternal($event)"
        :formatter="formatterLocal"
      ></b-form-input>
      <b-form-invalid-feedback :id="name + '-live-feedback'">{{
        validationContext.errors[0]
      }}</b-form-invalid-feedback>
    </b-form-group>
  </validation-provider>
</template>

<script>
import { formatter } from "../../helpers/dateHelper";

export default {
  name: "DateComponent",
  props: [
    "form",
    "name",
    "label",
    "rules",
    "type",
    "placeholder",
    "disabled",
    "labelcols",
    "contentcols",
    "dateformatoptions",
    "locale",
  ],
  data() {
    return {
      selectedItem: null,
    };
  },
  methods: {
    onChangeInternal($event) {
      this.$emit("onChange", $event);
    },
    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null;
    },
    formatterLocal(value, event) {
      return formatter(value, event);
    },
  },
};
</script>

<style lang="scss" scoped></style>
