export const TIPOS_IDENTIFICACIONES = [
  { value: "C", text: "Cédula de Identidad" },
  { value: "O", text: "Cédula Residencia" },
  { value: "ENR", text: "Extranjero No Residente" },
  { value: "DIMEX", text: "Documento Único de Identificacion para extranjeros" },
  { value: "DIDI", text: "Documento de Identificación para Diplomáticos" },
];

export const TIPOS_DEPENDIENTES = [
  { value: "CY", text: "Conyuge" },
  { value: "HJ", text: "Hijo" },
];

export const TIPOS_BENIFICARIOS = [
  { value: "CY", text: "Conyuge" },
  { value: "HJ", text: "Hijo" },
  { value: "HN", text: "Hermano" },
  { value: "MD", text: "Madre" },
  { value: "PD", text: "Padre" },
  { value: "OT", text: "Otros" },
];

export const TIPOS_SINO = [
  { value: "S", text: "Si" },
  { value: "N", text: "No" },
];
