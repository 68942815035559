export function saltarBeneficiarios(observations) {
  return observations && (observations.includes("HN04") || observations.includes("HN05"));
}

export function saltarDependientes(observations) {
  return (
    observations &&
    (observations.includes("HN01") ||
      observations.includes("HN06") ||
      observations.includes("HN07"))
  );
}

export function planType(observations) {
  switch (observations) {
    case "HN01":
      return ["V"];
    case "HN02":
      return ["V", "G"];
    case "HN03":
      return ["V", "G", "D"];
    case "HN04":
      return ["G"];
    case "HN05":
      return ["G", "D"];
    case "HN06":
      return ["V", "A"];
    case "HN07":
      return ["A"];
    default:
      return [];
  }
}
