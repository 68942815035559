<!-- eslint-disable vue/valid-v-model -->
<template>
  <div>
    <b-container>
      <div>
        <b-modal v-model="modalShow">{{ messagge }}</b-modal>
      </div>
      <validation-observer ref="observer" v-slot="{ handleSubmit }">
        <b-form @submit.stop.prevent="handleSubmit(next)" autocomplete="off">
          <b-card>
            <template #header>
              <h6 class="mb-0">IV- INFORMACIÓN DE SEGUROS</h6>
            </template>

            ¿Usted o algún solicitante ha solicitado seguro o ha estado asegurado con Pan–American
            Life o tiene alguna póliza de seguros de salud colectiva o individual?

            <td style="width: 10%">
              <RadioComponent
                :form="form"
                label=" "
                name="insurance"
                rules="required"
                :options="control.TIPOS_SINO"
              />
            </td>

            <table class="table-one" v-if="form.insurance == 'S'">
              <tr>
                <!-- Headers-->
                <th>Asegurado</th>
                <th>Póliza</th>
                <th>Compañía de seguros</th>
                <th>No. de Póliza</th>
              </tr>
              <tr v-for="(item, index) in form.otras" v-bind:key="index">
                <!-- Rows-->
                <td style="width: 70%">
                  <b-form-input
                    type="text"
                    v-model="item.name"
                    :state="getValidationOtraName(index)"
                  ></b-form-input>
                </td>

                <td style="width: 10%">
                  <b-form-select
                    v-model="item.relation"
                    :options="control.TIPOS_POLIZA"
                    :state="getValidationOtraRelation(index)"
                  ></b-form-select>
                </td>

                <td style="width: 10%">
                  <b-form-input
                    type="text"
                    v-model="item.company"
                    :state="getValidationOtraCompany(index)"
                  ></b-form-input>
                </td>
                <td style="width: 10%">
                  <b-form-input
                    type="text"
                    v-model="item.number"
                    :state="getValidationOtraNumber(index)"
                  ></b-form-input>
                </td>

                <!-- BUTTONS -->
                <td style="width: 5%">
                  <b-btn
                    v-if="!rowEmptyOtras(index)"
                    pill
                    variant="outline-danger"
                    @click="limpiarOtras(index)"
                    >Borrar</b-btn
                  >
                </td>
              </tr>
            </table>
          </b-card>

          <b-card>
            <template #header>
              <h6 class="mb-0">V- INFORMACIÓN DE LOS MÉDICOS</h6>
            </template>

            ¿ Alguno de los solicitantes tiene un médico de cabecera o ha consultado a un
            especialista ? (en caso afirmativo, por favor explique):

            <RadioComponent
              :form="form"
              name="medical"
              label=" "
              rules="required"
              :options="control.TIPOS_SINO"
            />

            <table class="table-one" v-if="form.medical == 'S'">
              <tr>
                <!-- Headers-->
                <th>Solicitante</th>
                <th>Nombre del Médico</th>
                <th>Especialidad</th>
                <th>Teléfono</th>
              </tr>
              <tr v-for="(item, index) in form.medicos" v-bind:key="index">
                <!-- Rows-->
                <td style="width: 30%">
                  <b-form-input
                    type="text"
                    v-model="item.applicant"
                    :state="getValidationMedicoAplicant(index)"
                  ></b-form-input>
                </td>

                <td style="width: 30%">
                  <b-form-input
                    type="text"
                    v-model="item.name"
                    :state="getValidationMedicoName(index)"
                  ></b-form-input>
                </td>

                <td style="width: 30%">
                  <b-form-input
                    type="text"
                    v-model="item.speciality"
                    :state="getValidationMedicoSpeciality(index)"
                  ></b-form-input>
                </td>

                <td style="width: 40%">
                  <b-form-input
                    type="text"
                    v-model="item.phone"
                    :state="getValidationMedicoPhone(index)"
                  ></b-form-input>
                </td>

                <!-- BUTTONS -->
                <td style="width: 5%">
                  <b-btn
                    v-if="!rowEmptyMedicos(index)"
                    pill
                    variant="outline-danger"
                    @click="limpiarMedicos(index)"
                    >Borrar</b-btn
                  >
                </td>
              </tr>
            </table>

            <!-- ENVIO -->
            <b-row align-h="end" class="mt-4">
              <b-col md="4" cols="4" style="display: flex; justify-content: flex-end">
                <b-button @click="previous" variant="secondary">Anterior</b-button>
                <b-button type="submit" variant="primary">Siguiente</b-button>
              </b-col>
            </b-row>
          </b-card>
        </b-form>
      </validation-observer>
    </b-container>
  </div>
</template>

<script>
import RadioComponent from "@/components/controls/RadioComponent.vue";
import moment from "moment";
import {
  TIPOS_DEPENDIENTES,
  TIPOS_ESTADOCIVIL,
  TIPOS_ESTATURA_UNIDAD,
  TIPOS_PESO_UNIDAD,
  TIPOS_POLIZA,
  TIPOS_SEXO,
  TIPOS_SINO,
} from "../../../data/honduras/Selects";
import { saltarDependientes } from "../../../helpers/reglasHonduras";

export default {
  name: "Home",
  components: {
    RadioComponent,
  },
  computed: {
    formateDate(date) {
      return moment(String(date)).format("MM-DD-YYYY");
    },
  },
  data: function () {
    /* eslint-disable max-len */
    return {
      messagge: "",
      control: {
        LanguageDisabled: 1,
        TIPOS_ESTADOCIVIL,
        TIPOS_DEPENDIENTES,
        TIPOS_SEXO,
        TIPOS_ESTATURA_UNIDAD,
        TIPOS_PESO_UNIDAD,
        TIPOS_SINO,
        TIPOS_POLIZA,
      },
      form: {
        insurance: "",
        medical: "",
        otras: [
          {
            name: "",
            relation: "",
            company: "",
            number: "",
          },
          {
            name: "",
            relation: "",
            company: "",
            number: "",
          },
        ],
        medicos: [
          {
            applicant: "",
            name: "",
            speciality: "",
            phone: "",
          },
          {
            applicant: "",
            name: "",
            speciality: "",
            phone: "",
          },
        ],
      },
      modalShow: false,
      sessionId: "",
      shouldDisable: true,
    };
  },
  mounted: function () {
    this.$store.dispatch("app/loading", true);
    const { form } = this.$store.state.wizard;
    if (form === undefined || form === null || form === "") {
      this.$router.push("/");
    }
    this.form = form;

    this.$store.dispatch("app/loading", false);
  },
  methods: {
    previous() {
      const form = { ...this.form };

      const saltar_dependientes = saltarDependientes(this.form.observations);

      if (saltar_dependientes === true)
        this.$store
          .dispatch("wizard/save", { index: 1, form })
          .then(this.$router.push("/form/honduras-2.html"));
      else
        this.$store
          .dispatch("wizard/save", { index: 2, form })
          .then(this.$router.push("/form/honduras-3.html"));
    },
    next() {
      if (this.form.insurance == "S" && this.tableOtrasEmpty()) {
        this.$bvToast.toast(
          "Informó que tiene alguna otra póliza, por favor complete al menos una",
          {
            title: "Informacion incompleta",
            variant: "danger",
            solid: true,
          }
        );
        return false;
      }

      if (this.form.insurance != "S") {
        this.tableOtrasClear();
      }

      if (this.form.medical == "S" && this.tableMedicosEmpty()) {
        this.$bvToast.toast(
          "Informó que tiene medico de cabecera o especialista,  por favor complete al menos uno",
          {
            title: "Informacion incompleta",
            variant: "danger",
            solid: true,
          }
        );
        return false;
      }

      if (this.form.medical != "S") {
        this.tableMedicosClear();
      }

      if (this.validationOtras() && this.validationMedicos()) {
        this.$store
          .dispatch("wizard/save", this.form)
          .then(this.$router.push("/form/honduras-5.html"));
      }
    },
    rowEmptyOtras(index) {
      return (
        this.form.otras[index].name == "" &&
        this.form.otras[index].relation == "" &&
        this.form.otras[index].company == "" &&
        this.form.otras[index].number == ""
      );
    },
    limpiarOtras(index) {
      this.form.otras[index].name = "";
      this.form.otras[index].relation = "";
      this.form.otras[index].company = "";
      this.form.otras[index].number = "";
    },

    rowEmptyMedicos(index) {
      return (
        this.form.medicos[index].applicant == "" &&
        this.form.medicos[index].name == "" &&
        this.form.medicos[index].speciality == "" &&
        this.form.medicos[index].phone == ""
      );
    },

    limpiarMedicos(index) {
      this.form.medicos[index].applicant = "";
      this.form.medicos[index].name = "";
      this.form.medicos[index].speciality = "";
      this.form.medicos[index].phone = "";
    },

    tableMedicosClear() {
      for (let i = 0; i < this.form.medicos.length; i++) {
        this.limpiarMedicos(i);
      }
    },

    validationOtras() {
      let ok = true;

      for (let i = 0; i < this.form.otras.length; i++) {
        if (!this.rowEmptyOtras(i)) {
          ok =
            ok &&
            this.getValidationOtraName(i) &&
            this.getValidationOtraRelation(i) &&
            this.getValidationOtraCompany(i) &&
            this.getValidationOtraNumber(i);
        }
      }

      return ok;
    },

    validationMedicos() {
      let ok = true;

      for (let i = 0; i < this.form.medicos.length; i++) {
        if (!this.rowEmptyMedicos(i)) {
          ok =
            ok &&
            this.getValidationMedicoAplicant(i) &&
            this.getValidationMedicoName(i) &&
            this.getValidationMedicoSpeciality(i) &&
            this.getValidationMedicoPhone(i);
        }
      }

      return ok;
    },

    /* OTRAS */

    getValidationOtraName(index) {
      const state = this.rowEmptyOtras(index) || this.form.otras[index].name != "";

      if (state && this.form.otras[index].name == "") {
        return null;
      } else {
        return state;
      }
    },

    getValidationOtraRelation(index) {
      const state = this.rowEmptyOtras(index) || this.form.otras[index].relation != "";

      if (state && this.form.otras[index].relation == "") {
        return null;
      } else {
        return state;
      }
    },

    getValidationOtraCompany(index) {
      const state = this.rowEmptyOtras(index) || this.form.otras[index].company != "";

      if (state && this.form.otras[index].company == "") {
        return null;
      } else {
        return state;
      }
    },

    getValidationOtraNumber(index) {
      const state = this.rowEmptyOtras(index) || this.form.otras[index].number != "";

      if (state && this.form.otras[index].number == "") {
        return null;
      } else {
        return state;
      }
    },

    /* MEDICOS */

    getValidationMedicoAplicant(index) {
      const state = this.rowEmptyMedicos(index) || this.form.medicos[index].applicant != "";

      if (state && this.form.medicos[index].applicant == "") {
        return null;
      } else {
        return state;
      }
    },

    getValidationMedicoName(index) {
      const state = this.rowEmptyMedicos(index) || this.form.medicos[index].name != "";

      if (state && this.form.medicos[index].name == "") {
        return null;
      } else {
        return state;
      }
    },

    getValidationMedicoSpeciality(index) {
      const state = this.rowEmptyMedicos(index) || this.form.medicos[index].speciality != "";

      if (state && this.form.medicos[index].speciality == "") {
        return null;
      } else {
        return state;
      }
    },

    getValidationMedicoPhone(index) {
      const state = this.rowEmptyMedicos(index) || this.form.medicos[index].phone != "";

      if (state && this.form.medicos[index].phone == "") {
        return null;
      } else {
        return state;
      }
    },

    tableMedicosEmpty() {
      for (let i = 0; i < this.form.medicos.length; i++) {
        if (!this.rowEmptyMedicos(i)) {
          return false;
        }
      }
      return true;
    },

    tableOtrasEmpty() {
      for (let i = 0; i < this.form.otras.length; i++) {
        if (!this.rowEmptyOtras(i)) {
          return false;
        }
      }
      return true;
    },

    tableOtrasClear() {
      for (let i = 0; i < this.form.otras.length; i++) {
        this.limpiarOtras(i);
      }
    },
  },
};
</script>

<style scoped>
img.preview {
  width: 500px;
  background-color: white;
  border: 1px solid #ddd;
  padding: 5px;
}
.photo-preview {
  width: 150px;
  background-color: white;
  border: 1px solid #ddd;
  padding: 5px;
}
.fz16 {
  font-size: 16px;
}
.w100 {
  width: 100%;
}
.fr {
  float: right;
}
.furnituresContainer {
  border-bottom: 1px solid #ced4da;
}
.container {
  max-width: 1800px !important;
}
#input-BirthDay {
  margin-left: 3px;
}
.col-5 {
  background-color: red;
}

.td-button {
  width: 80px;
  overflow: hidden;
  display: inline-block;
  white-space: nowrap;
}
</style>
