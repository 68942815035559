export function emailIgualATitular(window) {
  const emailIndex = window.form.dependientes.findIndex(
    (e) => e.email.trim() == window.form.email.trim()
  );

  if (emailIndex != -1) {
    window.$bvToast.toast("El email del dependiente, no puede ser igual al email del titular", {
      title: "Información incorrecta",
      variant: "danger",
      solid: true,
    });
    return true;
  } else {
    return false;
  }
}

export function conyugeRepetido(window) {
  // ATENCION CODIGO DE CONYUGE PUEDE SER "C" O "CY"
  const conyuges = window.form.dependientes.filter(
    (e) => e.relation && e.relation.trim().substring(0, 1) == "C"
  );

  if (conyuges.length > 1) {
    window.$bvToast.toast("No puede haber dos dependientes con relación de Esposo(a) / Conyuge", {
      title: "Información incorrecta",
      variant: "danger",
      solid: true,
    });
    return true;
  } else {
    return false;
  }
}

export function emailsDuplicados(window) {
  const emails = window.form.dependientes
    .filter((e) => e.email && e.email.trim() != "")
    .map((m) => {
      return m.email;
    });

  const duplicados = new Set(emails).size != emails.length;

  if (duplicados) {
    window.$bvToast.toast("No puede haber emails duplicados en los dependientes", {
      title: "Información incorrecta",
      variant: "danger",
      solid: true,
    });
    return true;
  } else {
    return false;
  }
}

export function documentosDuplicados(window) {
  const documents = window.form.dependientes
    .filter((e) => e.document && e.document.trim() != "")
    .map((m) => {
      return m.document;
    });

  const duplicados = new Set(documents).size != documents.length;

  if (duplicados) {
    window.$bvToast.toast("No puede haber Nro de Identificación duplicados en los dependientes", {
      title: "Información incorrecta",
      variant: "danger",
      solid: true,
    });
    return true;
  } else {
    return false;
  }
}
