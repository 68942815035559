export const TIPOS_IDENTIFICACIONES = [
  { value: "C", text: "Cédula de Identidad" },
  { value: "O", text: "Cédula Residencia" },
  { value: "ENR", text: "Extranjero No Residente" },
  { value: "DIMEX", text: "Documento Único de Identificacion para extranjeros" },
  { value: "DIDI", text: "Documento de Identificación para Diplomáticos" },
];

export const TIPOS_DEPENDIENTES = [
  { value: "CY", text: "Conyuge" },
  { value: "HJ", text: "Hijo" },
];

export const TIPOS_BENIFICARIOS = [
  { value: "CY", text: "Conyuge" },
  { value: "HJ", text: "Hijo" },
];

export const TIPOS_SINO = [
  { value: "S", text: "Si" },
  { value: "N", text: "No" },
];

export const TIPOS_SALARIO = [
  { value: "M", text: "Mensual" },
  { value: "S", text: "Semanal" },
  { value: "Q", text: "Quincenal" },
];

export const TIPOS_ESTADOCIVIL = [
  {
    value: "Soltero",
    text: "Soltera/o",
  },
  {
    value: "Casado",
    text: "Casada/o",
  },
];

export const SEXO = [
  {
    value: "M",
    text: "Masculino",
  },
  {
    value: "F",
    text: "Femenino",
  },
];
