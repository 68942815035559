import axios from "axios";
import authHeader from "./auth-header";

const BASE_URL = window.location.origin + "/api/";

class FormService {
  send(data) {
    return axios.post(BASE_URL + "send", data, { headers: authHeader() }).then((response) => {
      return response;
    });
  }

  status(data) {
    return axios.post(BASE_URL + "status", data, { headers: authHeader() }).then((response) => {
      return response;
    });
  }

  statusSign(data) {
    return axios.post(BASE_URL + "statusSign", data, { headers: authHeader() }).then((response) => {
      return response;
    });
  }
}

export default new FormService();
