<template>
  <!-- WIZARD STEP # 5 -->
  <div class="container wizard">
    <div class="row">
      <div class="col-12 p-0">
        <h2>Documento con Firma Digital</h2>
      </div>
    </div>

    <div class="row content">
      <div class="col-12">
        <div v-if="accepted" class="alert alert-success" role="alert">
          Se ha realizado la firma del contrato de forma exitosa. Se le enviará por correo una copia
          del mismo.
        </div>
        <div v-if="!accepted" class="alert alert-danger" role="alert">
          El contrato no fue firmado. Por favor en caso de que necesite una revisión, contáctese con
          el gestor.
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  methods: {},
  computed: {
    accepted() {
      return this.$store.state.wizard.accepted;
    },
  },
};
</script>
