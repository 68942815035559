import { render, staticRenderFns } from "./Form01.vue?vue&type=template&id=6a0606fc&scoped=true"
import script from "./Form01.vue?vue&type=script&lang=js"
export * from "./Form01.vue?vue&type=script&lang=js"
import style0 from "./Form01.vue?vue&type=style&index=0&id=6a0606fc&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6a0606fc",
  null
  
)

export default component.exports