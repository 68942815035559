<template>
  <div class="container wizard">
    <div class="row">
      <div class="col-12 p-0">
        <h2>Documento con Firma Digital</h2>
      </div>
    </div>

    <div class="row content">
      <iframe
        v-if="this.url !== ''"
        :src="this.url"
        width="100%"
        height="900px"
        v-on:load="this.loadIFrame"
      ></iframe>
    </div>
  </div>
</template>

<script>
import FormService from "../../../services/form.service";

export default {
  data() {
    return {
      window: null,
      url: "",
      number: 0,
    };
  },
  components: {},
  mounted: function () {
    this.$store.dispatch("app/loading", true);

    const { form } = this.$store.state.wizard;

    FormService.send(form).then((response) => {
      console.log("Send response : ", response.data);
      this.getPdfInterval(response.data.eviID);
    });
  },

  methods: {
    getPdfInterval(uniqueId) {
      var $this = this;

      function requestIntervalFunction(uniqueId) {
        try {
          FormService.status({ uniqueId }).then((response) => {
            if (response.data.statusCode == "200") {
              console.log("Searching Sign ...", response.data);
              var url = response.data.bulkSignUrl;
              var signIn = response.data.signIn;

              if (signIn == "True" || signIn == "False") {
                console.log("Eureka Sign : ");
                $this.$store.dispatch("app/loading", false);
                $this.$store.dispatch("wizard/accepted", signIn == "True");
                clearInterval(requestIntervalId);
                $this.next();
              }

              if ($this.url == "") {
                console.log("Eureka URL: ", url);
                $this.url = url;
              }
            } else {
              console.log("Searching bulk ...", response.data);
            }
          });
        } catch {
          $this.$bvToast.toast(`Contactese con soporte de Evicertia`, {
            title: "Error al intentar recuperar el pdf",
            variant: "danger",
            solid: true,
          });
          $this.$store.dispatch("app/loading", false);
        }
      }

      var requestIntervalId = setInterval(requestIntervalFunction, 2000, uniqueId);
      setTimeout(() => {
        this.$store.dispatch("app/loading", false);
        clearInterval(requestIntervalId);
      }, 120000);
    },
    openUrl() {
      console.log("Abriendo...", this.url);

      window.open(this.url, "_top");
    },
    loadIFrame(e) {
      if (e.target.src != "") {
        this.$store.dispatch("app/loading", false);
      }
    },
    next() {
      this.$router.push("/form/costarica-6.html");
    },
  },
  computed: {
    form() {
      return this.$store.state.wizard.form;
    },
  },
};
</script>
