export const TIPOS_IDENTIFICACIONES = [
  { value: "TI", text: "Doc. Personal de Identificación" },
  { value: "PS", text: "Pasaporte" },
  { value: "CE", text: "Residencia" },
];

export const TIPOS_DEPENDIENTES = [
  { value: "C", text: "Esposo(a)" },
  { value: "H", text: "Hijo(a)" },
];

export const TIPOS_RELACION = [
  { value: "ESPOSA(O)", text: "Conyuge" },
  { value: "HIJA(O)", text: "Hijo" },
  { value: "HERMANA(O)", text: "Hermano" },
  { value: "MADRE", text: "Madre" },
  { value: "PADRE", text: "Padre" },
  { value: "OTROS", text: "Otros" },
];

export const TIPOS_SEXO = [
  { value: "F", text: "Femenino" },
  { value: "M", text: "Masculino" },
];

export const TIPOS_PLAN = [
  { value: "G", text: "Gastos Médicos" },
  { value: "D", text: "Dental" },
  { value: "GD", text: "Gastos Médicos y Dental" },
];

export const TIPOS_PLAN_GENERAL = [
  { value: "V", text: "Vida" },
  { value: "G", text: "Gastos Médicos" },
  { value: "D", text: "Dental" },
  { value: "A", text: "Accidentes Personales" },
];

export const TIPOS_ESTADOCIVIL = [
  {
    value: "S",
    text: "Soltera/o",
  },
  {
    value: "C",
    text: "Casada/o",
  },

  {
    value: "D",
    text: "Divorciada/o",
  },
  {
    value: "V",
    text: "Viuda/o",
  },
  {
    value: "U",
    text: "Unión Libre (Unión de Hecho)",
  },
];

export const TIPOS_ESTATURA_UNIDAD = [
  { value: "P", text: "Pulgadas" },
  { value: "M", text: "Mts" },
];

export const TIPOS_PESO_UNIDAD = [
  { value: "L", text: "Libra" },
  { value: "K", text: "Kgs" },
];

export const TIPOS_POLIZA = [
  { value: "C", text: "Colectiva" },
  { value: "I", text: "Individual" },
];

export const TIPOS_SINO = [
  { value: "S", text: "Si" },
  { value: "N", text: "No" },
];

export const TIPOS_CUENTASBANCARIAS = [
  { value: "AHORRO", text: "Ahorro" },
  { value: "CHEQUES", text: "Cheques" },
];
