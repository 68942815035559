<template>
  <div>
    <b-container>
      <div>
        <b-modal v-model="modalShow">{{ messagge }}</b-modal>
      </div>
      <validation-observer ref="observer" v-slot="{ handleSubmit }">
        <b-form @submit.stop.prevent="handleSubmit(next)" autocomplete="off">
          <b-card>
            <template #header>
              <h6 class="mb-0">Datos para ser completados por el Empleado:</h6>
            </template>
            <b-row>
              <!-- FIRST LINE -->
              <b-col md="3" cols="12">
                <TextComponent :form="form" name="lastName" label="Primer Apellido" disabled />
              </b-col>
              <b-col md="3" cols="12">
                <TextComponent
                  :form="form"
                  name="secondLastName"
                  label="Segundo Apellido"
                  disabled
                />
              </b-col>

              <b-col md="3" cols="12">
                <TextComponent :form="form" name="firstName" label="Primer Nombre" disabled />
              </b-col>

              <b-col md="3" cols="12">
                <TextComponent :form="form" name="secondFirstName" label="Segundo Nombre" />
              </b-col>

              <!-- SECOND LINE -->

              <b-col md="6" cols="12">
                <TextComponent :form="form" name="adress" label="Dirección" />
              </b-col>

              <b-col md="3" cols="12">
                <TextComponent :form="form" name="phone" label="Teléfono" />
              </b-col>

              <b-col md="3" cols="12">
                <TextComponent :form="form" name="ocupacity" label="Ocupación" disabled />
              </b-col>

              <!-- THIRD LINE -->

              <b-col md="6" cols="12">
                <TextComponent :form="form" name="contractor" label="Contratante" disabled />
              </b-col>
              <b-col md="3" cols="12">
                <TextComponent :form="form" name="branch" label="Sucursal" disabled />
              </b-col>
              <b-col md="3" cols="12">
                <TextComponent :form="form" name="ci" label="CI" disabled />
              </b-col>

              <!-- QUARTER LINE -->
              <b-col md="3" cols="12">
                <TextComponent
                  :form="form"
                  v-model="form.birthDay"
                  name="birthDay"
                  label="Fecha de Nacimiento"
                  disabled
                />
              </b-col>

              <b-col md="3" cols="12">
                <TextComponent
                  :form="form"
                  name="employeeDateFormat"
                  label="Fecha de Empleo"
                  type="date"
                />
              </b-col>

              <b-col md="2" cols="12">
                <RadioComponent
                  :form="form"
                  name="salary"
                  label="Salario Básico"
                  :options="control.TIPOS_SALARIO"
                />
                <!-- <TextComponent
                  :form="form"
                  name=""
                  label="Otros"
                  v-model="form"
                  :disabled="form.maritalStatus != ''"
                /> -->
              </b-col>
              <b-col md="2" cols="12">
                <RadioComponent
                  :form="form"
                  name="sex"
                  label="Sexo"
                  disabled
                  :options="control.SEXO"
                />
              </b-col>
              <b-col md="2" cols="12">
                <RadioComponent
                  :form="form"
                  name="maritalStatus"
                  label="Estado Civil"
                  :options="control.TIPOS_ESTADOCIVIL"
                />
                <!-- <TextComponent :form="form" name="" label="Otros" v-model="form" /> -->
              </b-col>

              <div class="col-md-6">
                <h6 class="d-inline-flex mr-3">Cobertura para gastos médicos</h6>
                <b-col md="12">
                  <RadioComponent
                    :form="form"
                    name="medicalCoverageEmployee"
                    label="Empleado"
                    :options="control.TIPOS_SINO"
                  />
                </b-col>
                <b-col md="12">
                  <RadioComponent
                    :form="form"
                    name="medicalCoverageDependents"
                    label="Dependientes"
                    :options="control.TIPOS_SINO"
                  />
                </b-col>
              </div>

              <div class="col-md-6">
                <h6 class="d-inline-flex mr-3">Cobertura para gastos dentales</h6>
                <b-col md="12">
                  <RadioComponent
                    :form="form"
                    name="dentalCoverageEmployee"
                    label="Empleado"
                    :options="control.TIPOS_SINO"
                  />
                </b-col>
                <b-col md="12">
                  <RadioComponent
                    :form="form"
                    name="dentalCoverageDependents"
                    label="Dependientes"
                    :options="control.TIPOS_SINO"
                  />
                </b-col>
              </div>
            </b-row>

            <!-- ENVIO -->
            <b-row align-h="end" class="mt-4">
              <b-col md="4" cols="4" style="display: flex; justify-content: flex-end">
                <b-button type="submit" variant="primary">Siguiente</b-button>
              </b-col>
            </b-row>
          </b-card>
        </b-form>
      </validation-observer>
    </b-container>
  </div>
</template>

<script>
import RadioComponent from "@/components/controls/RadioComponent.vue";
import TextComponent from "@/components/controls/TextComponent.vue";

import {
  TIPOS_IDENTIFICACIONES,
  TIPOS_ESTADOCIVIL,
  TIPOS_SALARIO,
  SEXO,
  TIPOS_SINO,
} from "../../../data/ecuador/Selects";

export default {
  name: "Home",
  components: {
    TextComponent,
    RadioComponent,
  },
  data: function () {
    return {
      messagge: "",
      control: {
        LanguageDisabled: 1,
        IdentificationsType: TIPOS_IDENTIFICACIONES,
        TIPOS_ESTADOCIVIL,
        TIPOS_SALARIO,
        SEXO,
        TIPOS_SINO,
      },
      form: {},
      modalShow: false,
      sessionId: "",
      shouldDisable: true,
    };
  },
  mounted: function () {
    this.$store.dispatch("app/loading", true);
    const { form } = this.$store.state.wizard;
    form.birthDay = this.formatDate(form.birthDay);
    if (form === undefined || form === null || form === "") {
      this.$router.push("/form/ecuador/");
      return;
    }

    this.form = form;

    this.$store.dispatch("app/loading", false);
  },

  methods: {
    formatDate(date) {
      // Si la longitud de la fecha es 8 en formato yyyymmdd
      if (date.length === 8) {
        const year = date.substring(0, 4);
        const month = date.substring(4, 6);
        const day = date.substring(6, 8);
        return `${day}/${month}/${year}`;
      } else if (date.includes("/")) {
        // Si contiene "/"  está en el formato mm/dd/yyyy
        const parts = date.split("/");
        const month = parts[0].padStart(2, "0");
        const day = parts[1].padStart(2, "0");
        const year = parts[2];
        return `${day}/${month}/${year}`;
      } else {
        // Si no se puede determinar el formato, devolver la fecha original
        return date;
      }
    },
    next() {
      const form = { ...this.form };

      if (form.observations.includes("SA")) {
        this.$store
          .dispatch("wizard/save", { index: 2, form })
          .then(this.$router.push("/form/ecuador-3.html"));
      } else {
        this.$store
          .dispatch("wizard/save", { index: 1, form })
          .then(this.$router.push("/form/ecuador-2.html"));
      }
    },
  },
};
</script>

<style scoped>
img.preview {
  width: 500px;
  background-color: white;
  border: 1px solid #ddd;
  padding: 5px;
}
.photo-preview {
  width: 150px;
  background-color: white;
  border: 1px solid #ddd;
  padding: 5px;
}
.fz16 {
  font-size: 16px;
}
.w100 {
  width: 100%;
}
.fr {
  float: right;
}
.furnituresContainer {
  border-bottom: 1px solid #ced4da;
}
.container {
  max-width: 1600px;
}
#input-BirthDay {
  margin-left: 3px;
}
.col-5 {
  background-color: red;
}
</style>
