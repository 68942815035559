<!-- eslint-disable vue/valid-v-model -->
<template>
  <div>
    <b-container>
      <div>
        <b-modal v-model="modalShow">{{ messagge }}</b-modal>
      </div>
      <validation-observer ref="observer" v-slot="{ handleSubmit }">
        <b-form @submit.stop.prevent="handleSubmit(next)" autocomplete="off">
          <b-card>
            <template #header>
              <h6 class="mb-0">ANTECEDENTES MEDICOS PERSONALES</h6>
            </template>
            <table class="table-one">
              <tr>
                <!-- Headers-->
                <th>¿Alguno de los solicitantes alguna vez ha padecido o padece?:</th>
                <th>Asegurado Principal</th>
                <th>Dependientes</th>
              </tr>
              <tr class="trRow" v-for="(item, index) in form.antecedentes" v-bind:key="index">
                <!-- Rows-->
                <td style="width: 70%">
                  <b-form-input type="text" v-model="item.text" disabled></b-form-input>

                  <div v-if="item.principal == 'S'">
                    <b-row>
                      <b-col>
                        <label for="input-live">Solicitante:</label>
                        <b-form-input
                          type="text"
                          v-model="item.informacionPrincipal.applicant"
                          required
                        ></b-form-input>
                      </b-col>
                      <b-col>
                        <label for="input-live">Condición:</label>
                        <b-form-input
                          type="text"
                          v-model="item.informacionPrincipal.condition"
                          required
                        ></b-form-input>
                      </b-col>
                      <b-col>
                        <label for="input-live">Período Desde Hasta:</label>
                        <b-form-input
                          type="text"
                          v-model="item.informacionPrincipal.periodo"
                          required
                        ></b-form-input>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col>
                        <label for="input-live">Tratamiento y resultados :</label>
                        <b-form-input
                          type="text"
                          v-model="item.informacionPrincipal.treatment"
                          required
                        ></b-form-input>
                      </b-col>
                      <b-col>
                        <label for="input-live">Información del médico:</label>
                        <b-form-input
                          type="text"
                          v-model="item.informacionPrincipal.information"
                          required
                        ></b-form-input>
                      </b-col>
                    </b-row>
                    <div v-if="index == 11">
                      <b-row>
                        <b-col> Por favor indique cantidad por día y años de consumo. </b-col>
                        <b-col>
                          Consumo por día
                          <b-form-input
                            type="number"
                            style="width: 10%"
                            v-model="item.informacionPrincipal.days"
                          ></b-form-input>
                        </b-col>
                        <b-col>
                          Años de consumo
                          <b-form-input
                            type="number"
                            style="width: 10%"
                            v-model="item.informacionPrincipal.years"
                          ></b-form-input>
                        </b-col>
                      </b-row>
                    </div>
                  </div>

                  <div v-if="item.dependent == 'S'">
                    <div style="margin-top: 10px">
                      <h5>Dependiente</h5>
                    </div>
                    <b-row>
                      <b-col>
                        <label for="input-live">Solicitante:</label>
                        <b-form-input
                          type="text"
                          v-model="item.informacionDependiente.applicant"
                          required
                        ></b-form-input>
                      </b-col>
                      <b-col>
                        <label for="input-live">Condición:</label>
                        <b-form-input
                          type="text"
                          v-model="item.informacionDependiente.condition"
                          required
                        ></b-form-input>
                      </b-col>
                      <b-col>
                        <label for="input-live">Período Desde Hasta:</label>
                        <b-form-input
                          type="text"
                          v-model="item.informacionDependiente.periodo"
                          required
                        ></b-form-input>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col>
                        <label for="input-live">Tratamiento y resultados :</label>
                        <b-form-input
                          type="text"
                          v-model="item.informacionDependiente.treatment"
                          required
                        ></b-form-input>
                      </b-col>
                      <b-col>
                        <label for="input-live">Información del médico:</label>
                        <b-form-input
                          type="text"
                          v-model="item.informacionDependiente.information"
                          required
                        ></b-form-input>
                      </b-col>
                    </b-row>
                    <div v-if="index == 11">
                      <b-row>
                        <b-col> Por favor indique cantidad por día y años de consumo. </b-col>
                        <b-col>
                          Consumo por día
                          <b-form-input
                            type="number"
                            style="width: 10%"
                            v-model="item.informacionDependiente.days"
                          ></b-form-input>
                        </b-col>
                        <b-col>
                          Años de consumo
                          <b-form-input
                            type="number"
                            style="width: 10%"
                            v-model="item.informacionDependiente.years"
                          ></b-form-input>
                        </b-col>
                      </b-row>
                    </div>
                  </div>
                </td>

                <td style="width: 10%">
                  <b-form-radio-group
                    v-model="item.principal"
                    :options="control.TIPOS_SINO"
                    value-field="value"
                    text-field="text"
                    required
                  ></b-form-radio-group>
                </td>

                <td style="width: 10%">
                  <b-form-radio-group
                    v-model="item.dependent"
                    :options="control.TIPOS_SINO"
                    value-field="value"
                    text-field="text"
                    required
                    :disabled="!form.hasDependientes"
                  ></b-form-radio-group>
                </td>
              </tr>
            </table>

            <!-- ENVIO -->
            <b-row align-h="end" class="mt-4">
              <b-col md="4" cols="4" style="display: flex; justify-content: flex-end">
                <b-button @click="previous" variant="secondary">Anterior</b-button>
                <b-button type="submit" variant="primary">Siguiente</b-button>
              </b-col>
            </b-row>
          </b-card>
        </b-form>
      </validation-observer>
    </b-container>
  </div>
</template>

<script>
//const MAX_VIEWDATA = 4;

const TIPOS_SINO = [
  { value: "S", text: "Si" },
  { value: "N", text: "No" },
];

import moment from "moment";
import {
  TIPOS_DEPENDIENTES,
  TIPOS_ESTADOCIVIL,
  TIPOS_ESTATURA_UNIDAD,
  TIPOS_PESO_UNIDAD,
  TIPOS_SEXO,
} from "../../../data/honduras/Selects";

export default {
  name: "Home",
  components: {},
  computed: {
    formateDate(date) {
      return moment(String(date)).format("MM-DD-YYYY");
    },
  },
  data: function () {
    /* eslint-disable max-len */
    return {
      messagge: "",
      control: {
        LanguageDisabled: 1,
        TIPOS_ESTADOCIVIL,
        TIPOS_DEPENDIENTES,
        TIPOS_SEXO,
        TIPOS_ESTATURA_UNIDAD,
        TIPOS_PESO_UNIDAD,
        TIPOS_SINO,
      },
      form: {},
      hasDependientes: true,
      modalShow: false,
      sessionId: "",
      shouldDisable: true,
    };
  },
  mounted: function () {
    this.$store.dispatch("app/loading", true);
    const { form } = this.$store.state.wizard;
    if (form === undefined || form === null || form === "") {
      this.$router.push("/");
    }

    this.form = form;

    // SI NO HAY DEPENDIENTES, DISABLED SEGUNDA COLUMNAS DE RADIO BUTTON
    // Y MARCAS A TODOS COMO "NO"
    // EN LA CARGA DEL FORMULARIO.
    this.form.hasDependientes =
      this.form.dependientes.filter((e) => e.name.trim() != "").length != 0;

    if (!this.form.hasDependientes) {
      for (var i = 0; i++; i < this.form.dependientes.length) {
        this.form.antecedentes[i].dependent = "N";
      }
    }

    this.$store.dispatch("app/loading", false);
  },
  methods: {
    previous() {
      const form = { ...this.form };

      this.$store
        .dispatch("wizard/save", { index: 3, form })
        .then(this.$router.push("/form/honduras-4.html"));
    },
    next() {
      if (this.validation()) {
        this.$store
          .dispatch("wizard/save", this.form)
          .then(this.$router.push("/form/honduras-6.html"));
      }
    },
    rowEmpty(index) {
      return index !== 0;
    },
    limpiar(index) {
      console.log(index);
    },

    validation() {
      let ok = true;

      return ok;
    },
    /*
    changeSiNO() {
      let count = 0;

      const antecedentes = this.form.antecedentes.map((item) => {
        const viewData = item.principal == "S" || item.dependent == "S";

        if (viewData == true && count < MAX_VIEWDATA) {
          count++;
          return { ...item, viewData: true };
        } else {
          return {
            ...item,
            viewData: false,
            applicant: "",
            condition: "",
            dateFrom: "",
            dateTo: "",
            treatment: "",
            information: "",
          };
        }
      });

      this.form = { ...this.form, antecedentes };
    },
    */
  },
};
</script>

<style scoped>
img.preview {
  width: 500px;
  background-color: white;
  border: 1px solid #ddd;
  padding: 5px;
}
.photo-preview {
  width: 150px;
  background-color: white;
  border: 1px solid #ddd;
  padding: 5px;
}
.fz16 {
  font-size: 16px;
}
.w100 {
  width: 100%;
}
.fr {
  float: right;
}
.furnituresContainer {
  border-bottom: 1px solid #ced4da;
}
.container {
  max-width: 1800px !important;
}
#input-BirthDay {
  margin-left: 3px;
}
.col-5 {
  background-color: red;
}

.td-button {
  width: 80px;
  overflow: hidden;
  display: inline-block;
  white-space: nowrap;
}
.trRow {
  border: 1px solid #ced4da;
  padding: 3px;
  vertical-align: middle;
}
</style>
