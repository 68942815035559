<template>
  <validation-provider name="label">
    <b-form-input
      type="text"
      :name="name"
      :placeholder="placeholder"
      :state="state"
      v-model="form[name]"
      :disabled="disabled"
      :formatter="formatterLocal"
    ></b-form-input>
  </validation-provider>
</template>

<script>
import { formatter } from "../../helpers/dateHelper";

export default {
  name: "DateGrid",
  props: ["form", "name", "label", "rules", "state", "placeholder", "disabled"],
  methods: {
    formatterLocal(value, event) {
      return formatter(value, event);
    },
  },
};
</script>

<style lang="scss" scoped></style>
