<template>
  <div>
    <b-container>
      <div>
        <b-modal v-model="modalShow">{{ messagge }}</b-modal>
      </div>
      <validation-observer ref="observer" v-slot="{ handleSubmit }">
        <b-form @submit.stop.prevent="handleSubmit(next)" autocomplete="off">
          <b-card>
            <template #header>
              <h6 class="mb-0">BENEFICIARIOS DEL SEGURO DE VIDA / MYDA</h6>
            </template>
            <h5>Beneficiario</h5>
            <div class="table-responsive">
              <table class="table-one">
                <tr>
                  <!-- Headers-->
                  <th>Nombre</th>
                  <th>Apellido</th>
                  <th>Parentesco</th>
                  <th>%</th>
                  <th>Tipo</th>

                  <th style="width: 70px"></th>
                </tr>
                <tr
                  v-for="(item, index) in form.beneficiarios"
                  v-bind:key="index"
                  style="width: 100%"
                >
                  <!-- Rows-->
                  <td style="width: 35%">
                    <b-form-input
                      type="text"
                      v-model="item.name"
                      :state="getValidationName(index)"
                    ></b-form-input>
                  </td>

                  <td style="width: 30%">
                    <b-form-input
                      type="text"
                      v-model="item.lastName"
                      :state="getValidationLastName(index)"
                    ></b-form-input>
                  </td>

                  <td style="width: 10%">
                    <b-form-select
                      v-model="item.relation"
                      :options="control.TIPOS_RELACION"
                      :state="getValidationRelaction(index)"
                    ></b-form-select>
                  </td>

                  <td style="width: 10%">
                    <validation-provider name="label">
                      <b-form-input
                        type="number"
                        min="0"
                        max="100"
                        v-model="item.percentage"
                        :state="getValidationPercentage(index)"
                      ></b-form-input>
                    </validation-provider>
                  </td>

                  <td style="width: 10%">
                    <b-form-select
                      v-model="item.type"
                      :options="control.TIPOS_BENEFICIARIOS"
                      :state="getValidationType(index)"
                    ></b-form-select>
                  </td>

                  <td style="width: 5%">
                    <b-btn
                      v-if="!rowEmpty(index)"
                      pill
                      variant="outline-danger"
                      @click="limpiar(index)"
                      >Borrar</b-btn
                    >
                  </td>
                </tr>
              </table>
            </div>
            <p stlye="padding-top:1rem">
              Autorizo a la Compañía a compartir la información sobre mis diagnósticos, antecedentes
              de salud y demás información personal con el Contratante, las empresas afiliadas a Pan
              American Life, S.A. Seguro de Personas, los administradores de red de proveedores
              médicos, proveedores médicos y/o el intermediario de seguros, entre otros, durante la
              administración de la póliza de seguro colectivo de la que soy parte. Dicha
              autorización se hace extensiva a la información personal de mis dependientes. Doy mi
              consentimiento para que la Compañía me envíe por medio de comunicación digital toda la
              información y documentación relacionada con mi cobertura bajo la póliza de seguro
              colectivo de la que soy parte.
            </p>

            <!-- ENVIO -->
            <b-row align-h="end" class="mt-4">
              <b-col md="4" cols="4" style="display: flex; justify-content: flex-end">
                <b-button @click="previous" variant="secondary">Anterior</b-button>
                <b-button type="submit" variant="primary">Siguiente</b-button>
              </b-col>
            </b-row>
          </b-card>
        </b-form>
      </validation-observer>
    </b-container>
  </div>
</template>

<script>
import { TIPOS_BENEFICIARIOS, TIPOS_RELACION } from "../../../data/elsalvador/Selects";
import { saltarDependientes } from "../../../helpers/reglasElSalvador";

export default {
  name: "Home",
  components: {},
  data: function () {
    return {
      messagge: "",
      control: { LanguageDisabled: 1, TIPOS_RELACION, TIPOS_BENEFICIARIOS },
      form: {},
      modalShow: false,
      sessionId: "",
      shouldDisable: true,
    };
  },
  mounted: function () {
    this.$store.dispatch("app/loading", true);
    const { form } = this.$store.state.wizard;
    if (form === undefined || form === null || form === "") {
      this.$router.push("/");
    }
    this.form = form;
    this.$store.dispatch("app/loading", false);
  },
  methods: {
    previous() {
      const form = { ...this.form };

      this.$store
        .dispatch("wizard/save", { index: 1, form })
        .then(this.$router.push("/form/elsalvador-1.html"));
    },
    next() {
      const form = { ...this.form };
      if (this.tableEmpty()) {
        this.$bvToast.toast("Por favor cargue al menos un beneficiario", {
          title: "Informacion incompleta",
          variant: "danger",
          solid: true,
        });
        return false;
      }
      if (this.validation()) {
        const saltar_dependientes = saltarDependientes(this.form.observations);

        if (saltar_dependientes === true)
          this.$store
            .dispatch("wizard/save", { index: 3, form })
            .then(this.$router.push("/form/elsalvador-4.html"));
        else
          this.$store
            .dispatch("wizard/save", { index: 2, form })
            .then(this.$router.push("/form/elsalvador-3.html"));
      } else {
        this.$bvToast.toast("Por favor controle la información cargada en beneficiarios ", {
          title: "Información incompleta",
          variant: "danger",
          solid: true,
        });
      }
    },
    validation() {
      let ok = true;
      for (let i = 0; i < this.form.beneficiarios.length; i++) {
        if (!this.rowEmpty(i))
          ok =
            ok &&
            this.getValidationName(i) &&
            this.getValidationLastName(i) &&
            this.getValidationPercentage(i) &&
            this.getValidationRelaction(i) &&
            this.getValidationType(i);
      }
      return ok;
    },
    tableEmpty() {
      for (let i = 0; i < this.form.beneficiarios.length; i++) {
        if (this.form.beneficiarios[i].type == "P") {
          if (!this.rowEmpty(i)) {
            return false;
          }
        }
      }
      return true;
    },
    rowEmpty(index) {
      return (
        this.form.beneficiarios[index].name == "" &&
        this.form.beneficiarios[index].lastName == "" &&
        this.form.beneficiarios[index].percentage == "" &&
        this.form.beneficiarios[index].relation == "" &&
        this.form.beneficiarios[index].type == ""
      );
    },
    limpiar(index) {
      this.form.beneficiarios[index].name = "";
      this.form.beneficiarios[index].lastName = "";
      this.form.beneficiarios[index].percentage = "";
      this.form.beneficiarios[index].relation = "";
      this.form.beneficiarios[index].type = "";
    },
    getValidationName(index) {
      const state = this.rowEmpty(index) || this.form.beneficiarios[index].name != "";

      if (state && this.form.beneficiarios[index].name == "") {
        return null;
      } else {
        return state;
      }
    },
    getValidationLastName(index) {
      const state = this.rowEmpty(index) || this.form.beneficiarios[index].lastName != "";

      if (state && this.form.beneficiarios[index].lastName == "") {
        return null;
      } else {
        return state;
      }
    },
    getValidationPercentage(index) {
      const type = this.form.beneficiarios[index].type;

      const valor = Number(this.form.beneficiarios[index].percentage);
      const total = this.form.beneficiarios.reduce(
        (total, current) => total + (current.type === type ? Number(current.percentage) : 0),
        0
      );

      const state = this.rowEmpty(index) || (valor >= 1 && valor <= 100);

      if (state && this.form.beneficiarios[index].percentage == "") {
        return null;
      } else {
        return state && total == 100;
      }
    },
    getValidationRelaction(index) {
      const state = this.rowEmpty(index) || this.form.beneficiarios[index].relacion != "";

      if (state && this.form.beneficiarios[index].relation == "") {
        return null;
      } else {
        return state;
      }
    },

    getValidationType(index) {
      const state = this.rowEmpty(index) || this.form.beneficiarios[index].type != "";

      if (state && this.form.beneficiarios[index].type == "") {
        return null;
      } else {
        return state;
      }
    },
  },
};
</script>

<style scoped>
img.preview {
  width: 500px;
  background-color: white;
  border: 1px solid #ddd;
  padding: 5px;
}
.photo-preview {
  width: 150px;
  background-color: white;
  border: 1px solid #ddd;
  padding: 5px;
}
.fz16 {
  font-size: 16px;
}
.w100 {
  width: 100%;
}
.fr {
  float: right;
}
.furnituresContainer {
  border-bottom: 1px solid #ced4da;
}
.container {
  max-width: 100% !important;
}
#input-BirthDay {
  margin-left: 3px;
}
.col-5 {
  background-color: red;
}

.td-button {
  width: 80px;
  overflow: hidden;
  display: inline-block;
  white-space: nowrap;
}
</style>
