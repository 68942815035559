<template>
  <div>
    <b-container>
      <div>
        <b-modal v-model="modalShow">{{ messagge }}</b-modal>
      </div>
      <validation-observer ref="observer" v-slot="{ handleSubmit }">
        <b-form @submit.stop.prevent="handleSubmit(next)" autocomplete="off">
          <b-card>
            <template #header>
              <h6 class="mb-0">Datos para ser completados por el Empleado:</h6>
            </template>
            <h5>
              Aplica para la cobertura de vida, identifique la cantidad del porcentaje hasta un
              máximo de 100%
            </h5>
            <h6>
              PARA BENEFICIO DE GASTOS MEDICOS / GASTOS DENTALES DEPENDIENTES ELEGIBLES: CONYUGE -
              HIJOS SOLTEROS 10 DIAS - 19 AÑOS
            </h6>
            <table class="table-one">
              <tr>
                <th>Primer Apellido</th>
                <th>Segundo Apellido</th>
                <th>Primer Nombre</th>
                <th>Segundo Nombre</th>
                <th>Parentezco</th>
                <th>Fecha Nacimiento (mm/dd/aaaa)</th>
                <th style="width: 70px"></th>
              </tr>
              <tr v-for="(item, index) in form.beneficiarios" v-bind:key="index">
                <td style="width: 15%">
                  <b-form-input
                    type="text"
                    v-model="item.firstName"
                    :state="getValidationName(index)"
                  ></b-form-input>
                </td>
                <td style="width: 15%">
                  <b-form-input
                    type="text"
                    v-model="item.secondFirstName"
                    :state="getValidationName(index)"
                  ></b-form-input>
                </td>
                <td style="width: 15%">
                  <b-form-input
                    type="text"
                    v-model="item.lastName"
                    :state="getValidationName(index)"
                  ></b-form-input>
                </td>
                <td style="width: 15%">
                  <b-form-input
                    type="text"
                    v-model="item.secondLastName"
                    :state="getValidationName(index)"
                  ></b-form-input>
                </td>

                <td style="width: 15%">
                  <b-form-select
                    v-model="item.relation"
                    :options="control.TIPOS_BENIFICARIOS"
                    :state="getValidationRelaction(index)"
                  ></b-form-select>
                </td>
                <td style="width: 20%">
                  <b-form-input
                    type="date"
                    v-model="item.birthDay"
                    :state="getValidationBirthDay(index)"
                  ></b-form-input>
                </td>

                <td style="width: 5%">
                  <b-btn
                    v-if="!rowEmpty(index)"
                    pill
                    variant="outline-danger"
                    @click="limpiar(index)"
                    >Borrar</b-btn
                  >
                </td>
              </tr>
            </table>

            <h6>BENEFICIARIOS DEL SEGURO DE VIDA / MyDA: NOMBRES / ALTERNATIVAS CANTIDAD</h6>
            <table class="table-one">
              <tr>
                <th>Primer Apellido</th>
                <th>Segundo Apellido</th>
                <th>Primer Nombre</th>
                <th>Segundo Nombre</th>
                <th>%</th>
                <th>Parentesco</th>
              </tr>
              <tr v-for="(item, index) in form.suplentes" v-bind:key="index">
                <td style="width: 15%">
                  <b-form-input
                    type="text"
                    v-model="item.firstName"
                    :state="getValidationName(index)"
                  ></b-form-input>
                </td>
                <td style="width: 15%">
                  <b-form-input
                    type="text"
                    v-model="item.secondFirstName"
                    :state="getValidationName(index)"
                  ></b-form-input>
                </td>
                <td style="width: 15%">
                  <b-form-input
                    type="text"
                    v-model="item.lastName"
                    :state="getValidationName(index)"
                  ></b-form-input>
                </td>
                <td style="width: 15%">
                  <b-form-input
                    type="text"
                    v-model="item.secondLastName"
                    :state="getValidationName(index)"
                  ></b-form-input>
                </td>

                <td style="width: 10%">
                  <validation-provider name="label">
                    <b-form-input
                      type="number"
                      min="0"
                      max="100"
                      v-model="item.percentage"
                      :state="getValidationPercentageSubstitute(index)"
                    ></b-form-input>
                  </validation-provider>
                </td>

                <td style="width: 15%">
                  <b-form-select
                    v-model="item.relation"
                    :options="control.TIPOS_BENIFICARIOS"
                    :state="getValidationRelaction(index)"
                  ></b-form-select>
                </td>

                <td style="width: 5%">
                  <b-btn
                    v-if="!rowEmptySubstitute(index)"
                    pill
                    variant="outline-danger"
                    @click="limpiarSubstitute(index)"
                    >Borrar</b-btn
                  >
                </td>
              </tr>
            </table>

            <!-- ENVIO -->
            <b-row align-h="end" class="mt-4">
              <b-col md="4" cols="4" style="display: flex; justify-content: flex-end">
                <b-button @click="previous" variant="secondary">Anterior</b-button>
                <b-button type="submit" variant="primary">Siguiente</b-button>
              </b-col>
            </b-row>
          </b-card>
        </b-form>
      </validation-observer>
    </b-container>
  </div>
</template>

<script>
import { TIPOS_BENIFICARIOS } from "../../../data/ecuador/Selects";

export default {
  name: "Home",
  data() {
    return {
      messagge: "",
      control: { LanguageDisabled: 1, TIPOS_BENIFICARIOS },
      form: {},
      modalShow: false,
      sessionId: "",
      shouldDisable: true,
      validationEnabled: false,
    };
  },
  mounted() {
    this.$store.dispatch("app/loading", true);
    const { form } = this.$store.state.wizard;
    console.log("Formulario de beneficiarios:", this.form);
    if (!form) {
      this.$router.push("/");
    }
    this.form = form;
    this.validationEnabled = false; // Desactivar validaciones al cargar la página
    this.$store.dispatch("app/loading", false);
  },
  methods: {
    previous() {
      const form = { ...this.form };

      this.$store
        .dispatch("wizard/save", { index: 1, form })
        .then(this.$router.push("/form/ecuador-1.html"));
    },
    next() {
      const form = { ...this.form };

      console.log("formulario", this.form);
      if (this.validationEnabled && this.validation() && this.validationSubstitute()) {
        if (form.observations.includes("SD") || form.observations.includes("VI"))
          this.$store
            .dispatch("wizard/save", { index: 3, form })
            .then(this.$router.push("/form/ecuador-4.html"));
        else
          this.$store
            .dispatch("wizard/save", { index: 2, form })
            .then(this.$router.push("/form/ecuador-3.html"));
      } else {
        this.$bvToast.toast("Por favor controle la información cargada en beneficiarios ", {
          title: "Información incompleta",
          variant: "danger",
          solid: true,
        });
      }
    },
    validation() {
      if (!this.validationEnabled) return true;
      let ok = true;
      for (let i = 0; i < this.form.beneficiarios.length; i++) {
        if (!this.rowEmpty(i))
          ok =
            ok &&
            this.getValidationName(i) &&
            this.getValidationPercentage(i) &&
            this.getValidationRelaction(i);
        this.getValidationBirthDay(i);
      }
      return ok;
    },
    validationSubstitute() {
      if (!this.validationEnabled) return true; // No se realizan validaciones si no está habilitado

      let ok = true;
      for (let i = 0; i < this.form.suplentes.length; i++) {
        if (!this.rowEmptySubstitute(i))
          ok =
            ok &&
            this.getValidationNameSubstitute(i) &&
            this.getValidationPercentageSubstitute(i) &&
            this.getValidationRelactionSubstitute(i);
      }
      return ok;
    },

    rowEmpty(index) {
      return (
        this.form.beneficiarios[index].firstName == "" &&
        this.form.beneficiarios[index].secondFirstName == "" &&
        this.form.beneficiarios[index].lastName == "" &&
        this.form.beneficiarios[index].secondLastName == "" &&
        this.form.beneficiarios[index].birthDay == "" &&
        this.form.beneficiarios[index].relation == ""
      );
    },
    limpiar(index) {
      this.form.beneficiarios[index].firstName = "";
      this.form.beneficiarios[index].secondFirstName = "";
      this.form.beneficiarios[index].lastName = "";
      this.form.beneficiarios[index].secondLastName = "";
      this.form.beneficiarios[index].birthDay = "";
      this.form.beneficiarios[index].relation = "";
    },

    getValidationName(index) {
      const isEmpty =
        this.form.beneficiarios[index].firstName == "" &&
        this.form.beneficiarios[index].secondFirstName === "" &&
        this.form.beneficiarios[index].lastName === "" &&
        this.form.beneficiarios[index].secondLastName === "";

      return !isEmpty && !this.rowEmpty(index);
    },

    getValidationBirthDay(index) {
      const state = this.rowEmpty(index) || this.form.dependientes[index].birthDay != "";

      if (state && this.form.dependientes[index].birthDay == "") {
        return null;
      } else {
        return state;
      }
    },
    getValidationPercentage(index) {
      const valor = Number(this.form.beneficiarios[index].percentage);
      const total = this.form.beneficiarios.reduce(
        (total, current) => total + Number(current.percentage),
        0
      );

      const state = this.rowEmpty(index) || (valor >= 1 && valor <= 100);

      if (state && this.form.beneficiarios[index].percentage == "") {
        return null;
      } else {
        return state && total == 100;
      }
    },
    getValidationRelaction(index) {
      const state =
        this.rowEmpty(index) ||
        TIPOS_BENIFICARIOS.map((e) => e.value).includes(this.form.beneficiarios[index].relation);

      if (state && this.form.beneficiarios[index].relation == "") {
        return null;
      } else {
        return state;
      }
    },

    // SUPLENTES

    rowEmptySubstitute(index) {
      return (
        this.form.suplentes[index].firstName == "" &&
        this.form.suplentes[index].secondFirstName == "" &&
        this.form.suplentes[index].lastName == "" &&
        this.form.suplentes[index].secondLastName == "" &&
        this.form.suplentes[index].birthDay == "" &&
        this.form.suplentes[index].relation == ""
      );
    },
    limpiarSubstitute(index) {
      this.form.suplentes[index].firstName = "";
      this.form.suplentes[index].secondFirstName = "";
      this.form.suplentes[index].lastName = "";
      this.form.suplentes[index].secondLastName = "";
      this.form.suplentes[index].birthDay = "";
      this.form.suplentes[index].relation = "";
    },

    getValidationNameSubstitute(index) {
      const substitute = this.form.suplentes[index];
      const state =
        this.rowEmptySubstitute(index) ||
        substitute.firstName.trim() !== "" ||
        substitute.secondFirstName.trim() !== "" ||
        substitute.lastName.trim() !== "" ||
        substitute.secondLastName.trim() !== "";

      if (
        state &&
        substitute.firstName === "" &&
        substitute.secondFirstName === "" &&
        substitute.lastName === "" &&
        substitute.secondLastName === ""
      ) {
        return null;
      } else {
        return state;
      }
    },

    getValidationPercentageSubstitute(index) {
      const valor = Number(this.form.suplentes[index].percentage);
      const total = this.form.suplentes.reduce(
        (total, current) => total + Number(current.percentage),
        0
      );

      const state = this.rowEmptySubstitute(index) || (valor >= 1 && valor <= 100);

      if (state && this.form.suplentes[index].percentage == "") {
        return null;
      } else {
        return state && total == 100;
      }
    },
  },
};
</script>

<style scoped>
img.preview {
  width: 500px;
  background-color: white;
  border: 1px solid #ddd;
  padding: 5px;
}
.photo-preview {
  width: 150px;
  background-color: white;
  border: 1px solid #ddd;
  padding: 5px;
}
.fz16 {
  font-size: 16px;
}
.w100 {
  width: 100%;
}
.fr {
  float: right;
}
.furnituresContainer {
  border-bottom: 1px solid #ced4da;
}
.container {
  max-width: 1600px;
}
#input-BirthDay {
  margin-left: 3px;
}
.col-5 {
  background-color: red;
}

.td-button {
  width: 80px;
  overflow: hidden;
  display: inline-block;
  white-space: nowrap;
}
</style>
