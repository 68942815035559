export function saltarBeneficiarios(observations) {
  return observations && (observations.includes("GT04") || observations.includes("GT05"));
}

export function saltarDependientes(observations) {
  return (
    observations &&
    (observations.includes("GT01") ||
      observations.includes("GT06") ||
      observations.includes("GT07"))
  );
}

export function planType(observations) {
  if (observations.includes("GT01")) {
    return ["V"];
  }
  if (observations.includes("GT02")) {
    return ["V", "G"];
  }
  if (observations.includes("GT03")) {
    return ["V", "G", "D"];
  }
  if (observations.includes("GT04")) {
    return ["G"];
  }
  if (observations.includes("GT05")) {
    return ["G", "D"];
  }
  if (observations.includes("GT06")) {
    return ["V", "A"];
  }
  if (observations.includes("GT07")) {
    return ["A"];
  }

  return [];
}
