<template>
  <validation-provider :name="label" v-slot="validationContext" :rules="rules">
    <b-form-group
      :id="name"
      :label="label"
      :label-for="'input-' + name"
      :label-cols="labelcols"
      :content-cols="contentcols ? contentcols : undefined"
    >
      <b-form-select
        :id="'input-' + name"
        v-model="form[name]"
        :state="getValidationState(validationContext)"
        :aria-describedby="name + '-live-feedback'"
        :options="options"
        :disabled="disabled"
      ></b-form-select>
      <b-form-invalid-feedback :id="name + '-live-feedback'">{{
        validationContext.errors[0]
      }}</b-form-invalid-feedback>
    </b-form-group>
  </validation-provider>
</template>

<script>
export default {
  name: "SelectComponent",
  props: ["form", "name", "label", "rules", "options", "labelcols", "contentcols", "disabled"],
  data() {
    return {
      selectedItem: null,
    };
  },
  methods: {
    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null;
    },
  },
};
</script>
